export default class Rule {
  /**
   * @constructor
   * @param {Object} param
   * @param {String} param.name
   * @param {String} param.errorMessage
   * @param {Boolean} param.enabled
   */
  constructor({ name, errorMessage, enabled = true }) {
    this.name = name;
    this.errorMessage = errorMessage;
    this.enabled = enabled;
  }

  /**
   * @param {any} value
   * @return {Boolean}
   */
  validate(value) {
    console.error("Rule not implement validate logic: " + value);
    return false;
  }
}
