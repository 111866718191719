import { Rule } from "./index";
import { isset } from "@/utils/functions";

export default class RequiredRule extends Rule {
  /**
   * @constructor
   * @param {Object} param
   * @param {String} param.name
   * @param {String} param.errorMessage
   * @param {Boolean} param.enabled
   */
  constructor({ name, errorMessage, enabled = true, optional = false }) {
    super({ name, errorMessage, enabled });
    this.optional = optional;
  }

  /**
   * @param {any} value
   * @return {Boolean}
   */
  validate(value) {
    if (!this.optional || (this.optional && isset(value) && value !== "")) {
      var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(value);
    }
    return true;
  }
}
