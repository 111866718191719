import { FORM_ERRORS } from "@/components/form";

export default class FormRule {
  /**
   * @constructor
   * @param {Object} param
   * @param {String} param.errorMessage
   * @param {Boolean} param.enabled
   * @param {Function} param.rule
   */
  constructor({ rule, errorMessage, enabled = true }) {
    this.name = FORM_ERRORS;
    this.errorMessage = errorMessage;
    this.enabled = enabled;
    this._rule = rule;
  }

  /**
   * @param {any} value
   * @return {Boolean}
   */
  validate(form) {
    return this._rule(form);
  }
}
