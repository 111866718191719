<template>
  <div>
    <suc-select-component
      :form="form"
      :name="name"
      :title="title"
      :options="options"
      :reduce="(option) => option.value"
    />
  </div>
</template>

<script>
import { SucSelectComponent, Form } from "@/components/form";

import { addMinutes, format } from "date-fns";

export default {
  components: {
    SucSelectComponent,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    form: {
      type: Form,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    options() {
      let hours = [];
      for (let i = 0; i <= 47; i++) {
        const date = addMinutes(new Date(0, 0, 0, 0, 0), 30 * i);
        hours.push({ value: date, label: format(date, "HH:mm"), id: date.getTime() });
      }
      return hours;
    },
  },
  methods: {
    initValue(val) {
      if (this.$isset(val)) {
        let hour = this.options.find((x) => x.label == format(val, "HH:mm"));
        if (hour) {
          this.form[this.name] = hour.value;
        }
      }
    },
  },
};
</script>

<style></style>
