import { isset } from "@/utils/functions";
import { Rule } from "./index";

export default class RequiredRule extends Rule {
  /**
   * @constructor
   * @param {Object} param
   * @param {String} param.name
   * @param {String} param.errorMessage
   * @param {Boolean} param.enabled
   */
  constructor({ name, errorMessage, enabled = true }) {
    super({ name, errorMessage, enabled });
  }

  /**
   * @param {any} value
   * @return {Boolean}
   */
  validate(value) {
    return isset(value) && value !== "";
  }
}
