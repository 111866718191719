<template>
  <vue-modal
    :modal-title="$t('pages.planing-team-validate.hours_planning')"
    :show-spinner="isLoading"
    :show-modal="showModal"
    :loading-secondary-footer-button="isLoading"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onCloseButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template #body>
      <suc-form-has-error :form="form" />

      <div class="form-row">
        <div class="col">
          <time-hour-field
            :form="form"
            name="firstPartStartingTime"
            :title="$t('pages.planing-team-validate.first_part_starting_time')"
            ref="firstPartStartingTime"
          />
        </div>
        <div class="col">
          <time-hour-field
            :form="form"
            name="firstPartEndingTime"
            :title="$t('pages.planing-team-validate.first_part_ending_time')"
            ref="firstPartEndingTime"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <time-hour-field
            :form="form"
            name="secondPartStartingTime"
            :title="$t('pages.planing-team-validate.second_part_starting_time')"
            ref="secondPartStartingTime"
          />
        </div>
        <div class="col">
          <time-hour-field
            :form="form"
            name="secondPartEndingTime"
            :title="$t('pages.planing-team-validate.second_part_ending_time')"
            ref="secondPartEndingTime"
          />
        </div>
      </div>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";

import { Form, SucFormHasError } from "@/components/form";
import { FormRule, RequiredRule } from "@/components/form/data/rules";
import { SHOW_PLANNING_EDIT_HOUR_MODAL } from "@/pages/responsible/planing/data/events";
import api from "@/api/plan";
import { TimeHourField } from "@/pages/responsible/planing/validate/fields";

import { isEqual, isAfter } from "date-fns";

export default {
  components: {
    VueModal,
    TimeHourField,
    SucFormHasError,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      workHour: null,
      form: new Form({
        data: {
          firstPartStartingTime: null,
          firstPartEndingTime: null,
          secondPartStartingTime: null,
          secondPartEndingTime: null,
        },
      }),
    };
  },
  created() {
    EventBus.listen(SHOW_PLANNING_EDIT_HOUR_MODAL, (workHour) => {
      this.workHour = workHour;
      this.showModal = true;
    });
  },
  watch: {
    async showModal(val) {
      if (val) {
        this.isLoading = true;
        this.form.reset();
        this.$refs.firstPartStartingTime.initValue(this.workHour.firstPartStartingTime);
        this.$refs.firstPartEndingTime.initValue(this.workHour.firstPartEndingTime);
        this.$refs.secondPartStartingTime.initValue(this.workHour.secondPartStartingTime);
        this.$refs.secondPartEndingTime.initValue(this.workHour.secondPartEndingTime);

        this.isLoading = false;
      }
    },
  },
  beforeDestroy() {
    EventBus.off(SHOW_PLANNING_EDIT_HOUR_MODAL);
  },
  computed: {
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    fieldsRules() {
      let rules = [];
      if (this.$isset(this.form.firstPartStartingTime)) {
        rules.push(
          new RequiredRule({
            name: "firstPartEndingTime",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }
      if (this.$isset(this.form.secondPartStartingTime)) {
        rules.push(
          new RequiredRule({
            name: "secondPartEndingTime",
            errorMessage: this.$t("components.fields.rules.required"),
          })
        );
      }
      return rules;
    },
    formsRules() {
      let rules = [];
      if (
        this.$isset(this.form.firstPartStartingTime) &&
        this.$isset(this.form.firstPartEndingTime)
      ) {
        rules.push(
          new FormRule({
            rule: (x) => {
              return (
                isAfter(x.firstPartEndingTime, x.firstPartStartingTime) ||
                isEqual(x.firstPartEndingTime, x.firstPartStartingTime)
              );
            },
            errorMessage: this.$t("pages.planing-team-validate.wrong_first_part_period"),
          })
        );
      }
      if (
        this.$isset(this.form.secondPartStartingTime) &&
        this.$isset(this.form.secondPartEndingTime)
      ) {
        rules.push(
          new FormRule({
            rule: (x) => {
              return (
                isAfter(x.secondPartEndingTime, x.secondPartStartingTime) ||
                isEqual(x.secondPartEndingTime, x.secondPartStartingTime)
              );
            },
            errorMessage: this.$t("pages.planing-team-validate.wrong_second_part_period"),
          })
        );
      }

      if (
        this.$isset(this.form.secondPartStartingTime) &&
        this.$isset(this.form.firstPartEndingTime)
      ) {
        rules.push(
          new FormRule({
            rule: (x) => {
              return (
                isAfter(x.secondPartStartingTime, x.firstPartEndingTime) ||
                isEqual(x.firstPartEndingTime, x.secondPartStartingTime)
              );
            },
            errorMessage: this.$t("pages.planing-team-validate.wrong_second_part_period"),
          })
        );
      }
      return rules;
    },
  },
  methods: {
    onPrimaryButtonClicked() {
      this.form.setFieldsRules(this.fieldsRules);
      this.form.setFormRules(this.formsRules);
      if (this.form.validate()) {
        this.isLoading = true;

        let data = this.form.data();
        api
          .setDayPlanningHours({
            key: this.workHour.key,
            ...data,
          })
          .then(() => {
            this.$emit("changed");
            this.clear();

            this.showModal = false;
          })
          .catch((error) => {
            console.error(error.response);
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
    onSecondaryButtonClicked() {
      this.clear();
    },
    onCloseButtonClicked() {
      this.clear();
      this.showModal = false;
    },
    clear() {
      this.hourValue = "";
    },
  },
};
</script>
