<template>
  <suc-checkbox-field-component
    :title="$t('pages.planing-team.overtime.overtimeNight')"
    id="is-night-hour"
    v-bind="$attrs"
    v-on="$listeners"
  ></suc-checkbox-field-component>
</template>

<script>
import { SucCheckboxFieldComponent } from "@/components/form";

export default {
  components: {
    SucCheckboxFieldComponent,
  },
};
</script>

<style></style>
